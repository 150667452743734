import { useCallback, useState, useMemo } from "react"
import {
  assoc,
  assocPath,
  compose,
  dissoc,
  eqProps,
  equals,
  filter,
  find,
  includes,
  map,
  pick,
  pluck,
  propEq,
  when,
} from "ramda"

import styled from "@emotion/styled"
import { Accordion, Body, ConfirmationModal, Modal, Select, Switch, Tabs, Tags, Text } from "@ninjaone/components"

import { spacing, borderRadius, typography } from "@ninjaone/tokens"
import { Label } from "@ninjaone/components/src/Form/Label"

import { Box, Flex } from "js/includes/components/Styled"
import {
  localized,
  localizationKey,
  isNotNilOrEmpty,
  isNilOrEmpty,
  mapAndroidConnectionOptions,
  showErrorMessage,
} from "js/includes/common/utils"
import { useMountedState } from "js/includes/common/hooks"
import {
  AndroidPolicyAppsInstallTypes as InstallTypes,
  AndroidPolicyAppsInstallTypeOptions as installTypeOptions,
} from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util"
import showModal from "js/includes/common/services/showModal"
import {
  ManagedConfigurations,
  splitKeys,
} from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/android/applications/ManagedConfigurations"
import {
  applyDefaultManagedConfigValues,
  DistributionChannels,
  getManagedConfigByConnection,
  PermissionPolicy,
  permissionPolicyOptions,
} from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/android/applications/utils"
import { OldManagedConfigsAlert } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/android/applications/OldManagedConfigsAlert"
import { OverridesPermissionList } from "./OverridesPermissionList"

const PRODUCTION_VALUE = "PRODUCTION_VALUE"

const StyledTabContainer = styled(Flex)`
  flex-direction: column;
  padding: ${spacing[4]};
  gap: ${spacing[4]};
  overflow-y: auto;
  height: 70vh;
`

const StyledExpandableBox = styled(Flex)`
  background: ${({ theme }) => theme.colorBackgroundAccentNeutralWeakest};
  border-radius: ${borderRadius[1]};
  margin-top: ${spacing[1]};
  min-height: 256px;
`

const productionOption = {
  value: PRODUCTION_VALUE,
  LabelComponent: () => (
    <Flex justifyContent="space-between" alignItems="center" gap={spacing[2]}>
      <Body>{localized("Production")} </Body>
      <Box>
        <Tags labels={[{ id: "default", label: localized("Default") }]} />
      </Box>
    </Flex>
  ),
}

const connectedWorkAndPersonalAppOptions = [
  {
    value: "CONNECTED_WORK_AND_PERSONAL_APP_UNSPECIFIED",
    labelToken: localizationKey("Unspecified"),
  },
  {
    value: "CONNECTED_WORK_AND_PERSONAL_APP_DISALLOWED",
    labelToken: localizationKey("Disallowed"),
  },
  {
    value: "CONNECTED_WORK_AND_PERSONAL_APP_ALLOWED",
    labelToken: localizationKey("Allowed"),
  },
]

export const workProfileWidgetsEnum = {
  WORK_PROFILE_WIDGETS_UNSPECIFIED: "WORK_PROFILE_WIDGETS_UNSPECIFIED",
  WORK_PROFILE_WIDGETS_ALLOWED: "WORK_PROFILE_WIDGETS_ALLOWED",
  WORK_PROFILE_WIDGETS_DISALLOWED: "WORK_PROFILE_WIDGETS_DISALLOWED",
}

const workProfileWidgetsOptions = [
  {
    value: workProfileWidgetsEnum.WORK_PROFILE_WIDGETS_UNSPECIFIED,
    labelToken: localizationKey("Unspecified"),
  },
  {
    value: workProfileWidgetsEnum.WORK_PROFILE_WIDGETS_ALLOWED,
    labelToken: localizationKey("Allowed"),
  },
  {
    value: workProfileWidgetsEnum.WORK_PROFILE_WIDGETS_DISALLOWED,
    labelToken: localizationKey("Disallowed"),
  },
]

const autoUpdateModeOptions = [
  { value: "AUTO_UPDATE_MODE_UNSPECIFIED", labelToken: localizationKey("Unspecified") },
  { value: "AUTO_UPDATE_DEFAULT", labelToken: localizationKey("Default") },
  { value: "AUTO_UPDATE_POSTPONED", labelToken: localizationKey("Postponed") },
  { value: "AUTO_UPDATE_HIGH_PRIORITY", labelToken: localizationKey("High Priority") },
]

const userControlSettingsEnum = {
  USER_CONTROL_SETTINGS_UNSPECIFIED: "USER_CONTROL_SETTINGS_UNSPECIFIED",
  USER_CONTROL_ALLOWED: "USER_CONTROL_ALLOWED",
  USER_CONTROL_DISALLOWED: "USER_CONTROL_DISALLOWED",
}

const userControlSettingsOptions = [
  { value: userControlSettingsEnum.USER_CONTROL_SETTINGS_UNSPECIFIED, labelToken: localizationKey("Unspecified") },
  { value: userControlSettingsEnum.USER_CONTROL_ALLOWED, labelToken: localizationKey("Allowed") },
  { value: userControlSettingsEnum.USER_CONTROL_DISALLOWED, labelToken: localizationKey("Disallowed") },
]

const TabKey = {
  General: "general",
  Managed: "managed",
}

export const pickAppPolicyFields = application =>
  pick(
    [
      "disabled",
      "packageName",
      "installType",
      "defaultPermissionPolicy",
      "connectedWorkAndPersonalApp",
      "autoUpdateMode",
      "accessibleTrackIds",
      "permissionGrants",
      "delegatedScopes",
      "applicationSource",
      "inheritance",
      "active",
      "userControlSettings",
      "distributionChannel",
      "connectionId",
      "workProfileWidgets",
      ...(application.canBeManaged ? ["canBeManaged", "managedConfigurations"] : []),
    ],
    application,
  )

const ConfirmSaveModal = ({ unmount, onDiscardChanges, handleSave }) => {
  return (
    <ConfirmationModal
      titleText={localized("Unsaved changes")}
      descriptionText={localized("You have unsaved changes. Would you like to save your changes?")}
      actionToken={localizationKey("Save")}
      closeToken={localizationKey("Close without saving")}
      unmount={() => {
        unmount()
        onDiscardChanges()
      }}
      closeAction={unmount}
      onConfirm={() => {
        unmount()
        handleSave()
      }}
    />
  )
}

export default function MobileApplicationsPolicyModal({
  unmount,
  appSelected,
  // TODO: instead of prop, import directly in this same file as that's what's done in MobileAppliationsForm
  delegatedScopesOverrides,
  permissionOverrides,
  onUpdateApplicationPolicy,
  kioskCustomLauncherEnabled,
  applicationsList,
  androidEnterpriseConnections,
}) {
  const [disabled, setDisabled] = useMountedState(appSelected?.disabled || false)
  const [installType, setInstallType] = useMountedState(
    appSelected?.installType || InstallTypes.INSTALL_TYPE_UNSPECIFIED,
  )
  const [defaultPermissionPolicy, setDefaultPermissionPolicy] = useMountedState(
    appSelected?.defaultPermissionPolicy || PermissionPolicy.PERMISSION_POLICY_UNSPECIFIED,
  )
  const [connectedWorkAndPersonalApp, setConnectedWorkAndPersonalApp] = useMountedState(
    appSelected?.connectedWorkAndPersonalApp || "CONNECTED_WORK_AND_PERSONAL_APP_UNSPECIFIED",
  )
  const [autoUpdateMode, setAutoUpdateMode] = useMountedState(
    appSelected?.autoUpdateMode || "AUTO_UPDATE_MODE_UNSPECIFIED",
  )
  const [userControlSettings, setUserControlSettings] = useMountedState(
    appSelected?.userControlSettings || userControlSettingsEnum.USER_CONTROL_SETTINGS_UNSPECIFIED,
  )
  const [overrides, setOverrides] = useMountedState(appSelected?.permissionGrants || [])
  const [kioskInUseErrorMessage, setKioskInUseErrorMessage] = useMountedState(null)
  const [managedConfigurations, setManagedConfigurations] = useState(appSelected?.managedConfigurations ?? [])
  const [connectionId, setConnectionId] = useMountedState(androidEnterpriseConnections[0]?.id)
  const [workProfileWidgets, setWorkProfileWidgets] = useMountedState(
    appSelected?.workProfileWidgets || "WORK_PROFILE_WIDGETS_UNSPECIFIED",
  )

  const appTrackOptions = [
    productionOption,
    ...(isNotNilOrEmpty(appSelected?.appTracks)
      ? map(({ trackId, trackAlias }) => ({
          value: trackId,
          labelText: trackAlias,
        }))(appSelected?.appTracks)
      : []),
  ]

  const [accessibleTrackIds, setAccessibleTrackIds] = useMountedState(
    appSelected?.accessibleTrackIds?.[0] || PRODUCTION_VALUE,
  )
  const [delegatedScopes, setDelegatedScopes] = useMountedState(appSelected?.delegatedScopes || [])

  const connectionOptions = useMemo(() => mapAndroidConnectionOptions(androidEnterpriseConnections), [
    androidEnterpriseConnections,
  ])
  const kioskInstallTypeInUse = applicationsList.some(({ installType }) => installType === "KIOSK")

  const handleInstallType = value => {
    let currentErrorMessage = null

    if (equals(value, "KIOSK") && (kioskCustomLauncherEnabled || kioskInstallTypeInUse)) {
      const applicationWithKiosk = find(propEq("installType", "KIOSK"))(applicationsList)
      if (kioskCustomLauncherEnabled) {
        currentErrorMessage = localized("Multi-app kiosk is already set")
      } else if (kioskInstallTypeInUse && !eqProps("packageName", appSelected, applicationWithKiosk)) {
        currentErrorMessage = localized("Kiosk mode already set on another app")
      }
    }
    setKioskInUseErrorMessage(currentErrorMessage)
    setInstallType(value)
  }

  const handleSave = () => {
    const appPolicy = pickAppPolicyFields(appSelected)
    let applicationPolicyUpdated = {
      ...appPolicy,
      disabled,
      installType,
      defaultPermissionPolicy,
      connectedWorkAndPersonalApp,
      autoUpdateMode,
      permissionGrants: overrides,
      userControlSettings,
      distributionChannel: appSelected.distributionChannel,
      ...(appSelected.canBeManaged && {
        managedConfigurations: applyDefaultManagedConfigValues(
          managedConfigurations,
          connectionId,
          appSelected.managedProperties,
        ),
      }),
      delegatedScopes,
      workProfileWidgets,
    }
    if (appSelected.distributionChannel === DistributionChannels.PUBLIC_GOOGLE_HOSTED) {
      applicationPolicyUpdated = dissoc("connectionId", applicationPolicyUpdated)
    }
    if (accessibleTrackIds) {
      applicationPolicyUpdated =
        accessibleTrackIds === PRODUCTION_VALUE
          ? dissoc("accessibleTrackIds", applicationPolicyUpdated)
          : assoc("accessibleTrackIds", [accessibleTrackIds], applicationPolicyUpdated)
    }
    onUpdateApplicationPolicy(applicationPolicyUpdated)
    unmount()
  }

  const addPermission = permission => {
    const hasPermission = compose(isNotNilOrEmpty, find(propEq("permission", permission)))(overrides)
    if (!hasPermission) {
      const updatedOverrides = [
        ...overrides,
        {
          policy: PermissionPolicy.PERMISSION_POLICY_UNSPECIFIED,
          permission,
        },
      ]
      setOverrides(updatedOverrides)
    } else {
      showErrorMessage(localized("Permission was already added"))
    }
  }

  const getAvailablePermissionOverrides = () => {
    const selectedOverrides = pluck("permission", overrides)
    return filter(item => !includes(item.value, selectedOverrides))(permissionOverrides)
  }

  const handlePermissionValueChange = (permission, policy) => {
    const updatedOverrides = overrides.map(override => {
      if (override.permission === permission) {
        return {
          policy,
          permission,
        }
      } else {
        return override
      }
    })
    setOverrides(updatedOverrides)
  }

  const handleRemovePermission = permissionKey => {
    const updatedOverrides = overrides.filter(({ permission }) => permission !== permissionKey)
    setOverrides(updatedOverrides)
  }

  const updateManagedConfigField = useCallback(
    (key, value) => {
      setManagedConfigurations(configs => {
        const configPath = ["managedConfiguration", ...splitKeys(key)]
        const connectionConfig = configs.find(propEq("connectionId", connectionId))
        if (connectionConfig) {
          return configs.map(when(propEq("connectionId", connectionId), assocPath(configPath, value)))
        }
        return configs.concat(assocPath(configPath, value, { connectionId }))
      })
    },
    [setManagedConfigurations, connectionId],
  )

  const currentTemplateId = getManagedConfigByConnection(managedConfigurations, connectionId)?.templateId
  const getAvailableDelegatedScopesOverrides = () => {
    return filter(item => !includes(item.value, delegatedScopes))(delegatedScopesOverrides)
  }
  const addDelegatedScope = newScope => {
    const hasScope = includes(newScope)(delegatedScopes)
    if (!hasScope) {
      const updatedDelegatedScopes = [...delegatedScopes, newScope]
      setDelegatedScopes(updatedDelegatedScopes)
    } else {
      showErrorMessage(localized("Delegated scope was already added"))
    }
  }
  const handleRemoveDelegatedScopes = scopeKey => {
    const updatedScopes = delegatedScopes.filter(scope => scope !== scopeKey)
    setDelegatedScopes(updatedScopes)
  }

  const mapAsOverridesList = delegatesScopeList => {
    const mappedDelegatedScopes = map(({ value, labelToken }) => ({
      permission: value,
      policy: localized(labelToken),
    }))(delegatedScopesOverrides)
    return filter(({ permission }) => includes(permission, delegatesScopeList))(mappedDelegatedScopes)
  }

  const getTabs = () => {
    const tabs = [
      {
        key: TabKey.General,
        labelToken: localizationKey("General"),
        renderer: () => (
          <StyledTabContainer>
            <Switch checked={disabled} onChange={setDisabled} labelToken={localizationKey("Disabled")} />
            <Select
              labelId="installType"
              labelText={localized("Assignment type")}
              options={installTypeOptions}
              value={installType}
              onChange={handleInstallType}
              errorMessage={kioskInUseErrorMessage}
            />
            <Select
              labelId="defaultPermissionPolicy"
              labelText={localized("Default permission policy")}
              options={permissionPolicyOptions}
              value={defaultPermissionPolicy}
              onChange={setDefaultPermissionPolicy}
            />
            <Select
              labelId="connectedWorkAndPersonalApp"
              labelText={localized("Connected work and personal app")}
              options={connectedWorkAndPersonalAppOptions}
              value={connectedWorkAndPersonalApp}
              onChange={setConnectedWorkAndPersonalApp}
            />
            <Select
              labelId="workProfileWidgets"
              labelText={localized("Allow widget access in work profile")}
              options={workProfileWidgetsOptions}
              tooltipText={localized(
                "Allowing this option means the application will be able to add widgets to the home screen. This only refers to devices with work profile enabled.",
              )}
              value={workProfileWidgets}
              onChange={setWorkProfileWidgets}
            />
            <Select
              labelId="autoUpdateMode"
              labelText={localized("Auto update mode")}
              options={autoUpdateModeOptions}
              value={autoUpdateMode}
              onChange={setAutoUpdateMode}
            />
            <Select
              labelId="userControlSettings"
              labelText={localized("Allow force stop and clear data")}
              tooltipText={localized("Requires Android 11 and above")}
              options={userControlSettingsOptions}
              value={userControlSettings}
              onChange={setUserControlSettings}
            />
            <Select
              labelId="appTracks"
              labelText={localized("Application track for installation")}
              options={appTrackOptions}
              value={accessibleTrackIds}
              onChange={setAccessibleTrackIds}
            />
            <Select
              labelId="permissionOverrides"
              titleRenderer={() => (
                <Flex justifyContent="space-between">
                  <Label labelFor="permissionOverrides" labelText={localized("Per app permission overrides")} />
                  {overrides.length > 0 && (
                    <Body>
                      {overrides.length === 1
                        ? localized("1 override selected out of {{total}}", {
                            total: permissionOverrides.length,
                          })
                        : localized("{{count}} overrides selected out of {{total}}", {
                            count: overrides.length,
                            total: permissionOverrides.length,
                          })}
                    </Body>
                  )}
                </Flex>
              )}
              labelRenderer={() => <Body>{localized("Add permission to list")}</Body>}
              options={getAvailablePermissionOverrides()}
              value=""
              onChange={addPermission}
              triggerAriaLabel={localized("Per app permission overrides")}
              noOptionsText={
                permissionOverrides.length === 0
                  ? localized("App does not offer any permission overrides")
                  : localized("All available permission overrides are in use")
              }
            />
            <Accordion
              defaultExpandedMenu="selectedOverrides"
              items={[
                {
                  id: "selectedOverrides",
                  rendererTitle: () => (
                    <Body color="inputText">
                      {localized("Overrides ({{countOverrides}})", { countOverrides: overrides.length })}
                    </Body>
                  ),
                  rendererSubMenu: () => (
                    <StyledExpandableBox
                      {...(isNilOrEmpty(overrides) && { justifyContent: "center", alignItems: "center" })}
                    >
                      {isNilOrEmpty(overrides) ? (
                        <Body color="colorTextStrong">{localized("No overrides selected")}</Body>
                      ) : (
                        <OverridesPermissionList
                          overrides={overrides}
                          onValueChange={handlePermissionValueChange}
                          onRemovePermission={handleRemovePermission}
                        />
                      )}
                    </StyledExpandableBox>
                  ),
                },
              ]}
            />
            <Select
              labelId="appDelegateScopes"
              options={getAvailableDelegatedScopesOverrides()}
              value=""
              onChange={value => addDelegatedScope(value)}
              titleRenderer={() => (
                <Flex justifyContent="space-between">
                  <Label labelFor="appDelegateScopes" labelText={localized("Delegated scope overrides")} />
                  {delegatedScopes.length > 0 && (
                    <Body>
                      {delegatedScopes.length === 1
                        ? localized("1 override selected out of {{total}}", {
                            total: delegatedScopesOverrides.length,
                          })
                        : localized("{{count}} overrides selected out of {{total}}", {
                            count: delegatedScopes.length,
                            total: delegatedScopesOverrides.length,
                          })}
                    </Body>
                  )}
                </Flex>
              )}
              labelRenderer={() => <Body>{localized("Add scope to list")}</Body>}
              triggerAriaLabel={localized("Add a delegated scope override")}
            />
            <Accordion
              defaultExpandedMenu="selectedDelegateScopes"
              items={[
                {
                  id: "selectedDelegateScopes",
                  rendererTitle: () => (
                    <Body color="inputText">
                      {localized("Delegated scope overrides ({{countOverrides}})", {
                        countOverrides: delegatedScopes.length,
                      })}
                    </Body>
                  ),
                  rendererSubMenu: () => (
                    <StyledExpandableBox
                      {...(isNilOrEmpty(delegatedScopes) && { justifyContent: "center", alignItems: "center" })}
                    >
                      {isNilOrEmpty(delegatedScopes) ? (
                        <Body color="colorTextStrong">{localized("No delegated scope override selected")}</Body>
                      ) : (
                        <OverridesPermissionList
                          overrides={mapAsOverridesList(delegatedScopes)}
                          onValueChange={(_policy, value) => addDelegatedScope(value)}
                          onRemovePermission={handleRemoveDelegatedScopes}
                          showSelect={false}
                        />
                      )}
                    </StyledExpandableBox>
                  ),
                },
              ]}
            />
          </StyledTabContainer>
        ),
      },
    ]
    if (appSelected.canBeManaged) {
      tabs.push({
        key: TabKey.Managed,
        labelToken: localizationKey("Managed configurations"),
        renderer: () => {
          const connectionName = connectionId
            ? connectionOptions.find(option => option.value === connectionId)?.labelText
            : null

          return (
            <StyledTabContainer>
              <Select
                options={connectionOptions}
                labelRenderer={() => (
                  <Flex gap={spacing[2]} alignItems="center" overflow="hidden">
                    <Text type="headingS" fontWeight={typography.fontWeight.semiBold}>
                      {localized("Android Connection: ")}
                    </Text>
                    <Text type="headingS" key={connectionName}>
                      {connectionName}
                    </Text>
                  </Flex>
                )}
                value={connectionId}
                triggerAriaLabel={localized("Android Enterprise Connection")}
                onChange={setConnectionId}
                labelId="androidConnections"
                triggerMinWidth="100%"
              />
              {currentTemplateId && (
                <OldManagedConfigsAlert
                  {...{ connectionId, setManagedConfigurations }}
                  packageName={appSelected.packageName}
                  templateId={currentTemplateId}
                />
              )}
              <ManagedConfigurations
                {...{ managedConfigurations, updateManagedConfigField, connectionId }}
                propertiesList={appSelected.managedProperties}
              />
            </StyledTabContainer>
          )
        },
      })
    }
    return tabs
  }

  return (
    <Modal
      titleGroup={{
        titleText: `${localized("Edit applications policy")} - ${appSelected?.name ?? appSelected.packageName}`,
      }}
      closeAction={() => {
        const appPolicy = pickAppPolicyFields(appSelected)

        const updatedSettings = {
          ...appPolicy,
          disabled,
          installType,
          defaultPermissionPolicy,
          connectedWorkAndPersonalApp,
          autoUpdateMode,
          permissionGrants: overrides,
          userControlSettings,
          managedConfigurations,
          accessibleTrackIds: [accessibleTrackIds],
          workProfileWidgets,
        }

        // Add defaults or any other extra data for comparison
        const appSelectedWithDefaults = {
          ...appPolicy,
          accessibleTrackIds: appSelected?.accessibleTrackIds ?? [PRODUCTION_VALUE],
          permissionGrants: appSelected.permissionGrants ?? [],
          defaultPermissionPolicy:
            appSelected.defaultPermissionPolicy ?? PermissionPolicy.PERMISSION_POLICY_UNSPECIFIED,
          userControlSettings:
            appSelected.userControlSettings ?? userControlSettingsEnum.USER_CONTROL_SETTINGS_UNSPECIFIED,
          managedConfigurations: appSelected.managedConfigurations ?? [],
          workProfileWidgets:
            appSelected?.workProfileWidgets ?? workProfileWidgetsEnum.WORK_PROFILE_WIDGETS_UNSPECIFIED,
        }
        equals(updatedSettings, appSelectedWithDefaults)
          ? unmount()
          : showModal(<ConfirmSaveModal {...{ handleSave }} onDiscardChanges={unmount} />)
      }}
      size={appSelected.canBeManaged ? "lg" : "md"}
      buttons={[
        {
          type: "save",
          onClick: handleSave,
          labelToken: localizationKey("Update"),
          disabled: !!kioskInUseErrorMessage,
        },
      ]}
    >
      <Tabs tabs={getTabs()} />
    </Modal>
  )
}
